import * as React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import { useState } from "react";

// markup
const NotFoundPage = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
      <div className="font-inter max-w-7xl px-6 lg:px-8 mx-auto mt-28 mb-32  min-h-[46.9vh]">
        <SEO title="Page Not Found" lang="en" />
        <div className="w-full h-full  flex flex-col justify-center items-center space-y-8">
          <StaticImage
            alt="Crowdhaus App"
            placeholder="blurred"
            draggable="false"
            src="../images/404.png"
            className="w-80 md:w-[27rem] -mb-5"
          />
          <h3 className="text-3xl md:text-[2.75rem] font-semibold text-center">
            Looks like you’re lost.
          </h3>
          <p className="text-center max-w-[30rem] ">
            Take a look elsewhere, maybe at one of our{" "}
            <Link to="/#projects" className="underline">
              wonderful projects
            </Link>
            , or learn about{" "}
            <Link to="/#ourStory" className="underline ">
              our story
            </Link>
            . Lots of options, there’s not much to see right here.
          </p>
        </div>
      </div>
    );
  }
};

export default NotFoundPage;
